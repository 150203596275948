import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fetchAuthSession } from '@aws-amplify/auth';
import { deleteUser } from 'aws-amplify/auth';

const apiUrl = process.env.REACT_APP_BACKEND_ENV === 'development' 
  ? 'http://localhost:5000'  // Local backend for testing
  : process.env.REACT_APP_API_URL; // Production API

const SettingsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDeleteAccount = async () => {
    const confirmed = window.confirm(t('settings.deleteAccountConfirmation'));
    if (!confirmed) return;

    try {
      let token = null;

      // Fetch the auth token using `fetchAuthSession`
      try {
        const session = await fetchAuthSession();
        token = session?.tokens?.idToken?.toString();
      } catch (authError) {
        console.warn('User not authenticated:', authError);
        alert(t('settings.accountDeletionFailed')); // Inform the user if authentication fails
        return;
      }

      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      // Delete user data from your backend
      const response = await fetch(`${apiUrl}/api/recipes/user/delete`, {
        method: 'DELETE',
        headers,
        credentials: 'include', // Include cookies if necessary
      });

      if (!response.ok) {
        const errorMsg = await response.text();
        console.error('Account deletion failed on backend:', errorMsg);
        throw new Error(errorMsg || t('settings.accountDeletionFailed'));
      }

      // If backend deletion is successful, delete user from Cognito
      try {
        await deleteUser(); // Deletes the user from AWS Cognito
        alert(t('settings.accountDeleted'));
        navigate('/'); // Redirect after successful deletion
      } catch (cognitoError) {
        console.error('Failed to delete user from Cognito:', cognitoError);
        alert(t('settings.accountDeletionFailed'));
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      alert(t('settings.accountDeletionFailed'));
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('settings.title')}
      </Typography>
      <Button
        variant="contained"
        color="error"
        onClick={handleDeleteAccount}
      >
        {t('settings.deleteAccount')}
      </Button>
    </Container>
  );
};

export default SettingsPage;
