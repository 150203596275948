import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';

const BenefitsSection = () => {
  const { t } = useTranslation();

  const benefits = [
    {
      title: t('benefits.saveTime'),
      description: t('benefits.saveTimeDesc'),
      icon: <AccessTimeIcon fontSize="large" color="primary" />,
    },
    {
      title: t('benefits.dietaryCompatibility'),
      description: t('benefits.dietaryCompatibilityDesc'),
      icon: <CheckCircleIcon fontSize="large" color="secondary" />,
    },
    {
      title: t('benefits.recipeInformation'),
      description: t('benefits.recipeInformationDesc'),
      icon: <InfoIcon fontSize="large" color="success" />,
    },
  ];

  return (
    <Box sx={{ py: 6, backgroundColor: 'background.default' }}>
      <Typography variant="h4" align="center" gutterBottom>
        {t('benefits.title')}
      </Typography>
      <Typography variant="subtitle1" align="center" color="text.secondary" gutterBottom>
        {t('benefits.subtitle')}
      </Typography>

      <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
        {benefits.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                textAlign: 'center',
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minHeight: '180px',
                height: '100%',
                overflow: 'auto',
              }}
            >
              {benefit.icon}
              <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                {benefit.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {benefit.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BenefitsSection;
