import React from 'react';
import { Box, AppBar, Toolbar } from '@mui/material';
import Icon from './header/Icon';
import AppName from './header/AppName';
import LangaugeToggleButtons from './header/LangaugeToggleButtons';
import DayNightButton from './header/DayNightButton';
import UserButton from './header/UserButton';
import MyRecipes from './header/MyRecipes';
import { useMediaQuery, useTheme } from '@mui/material';


const Header = ({darkMode, toggleDarkMode, user, setUser}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <AppBar position="static" sx={{ bgcolor: 'primary.main', padding: '0 20px' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Left Section: Logo and App Name */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {/* Icon */}
            <Icon />
            {/* App Name */}
            {isMobile ? null : <AppName />}
          </Box>

          {/* Middle Section: Navigation Links */}
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: '20px' }}>
            {/* My Recipes */}
            <MyRecipes />
          </Box>

          {/* Right Section: Language Toggle, Day/Night Toggle, and User Button */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {/* Language Toggle */}
            <LangaugeToggleButtons />
            {/* Day/Night Toggle */}
            <DayNightButton darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            {/* User Button */}
            <UserButton user={user} setUser={setUser} />
          </Box>
        </Toolbar>
      </AppBar>
  );
};

export default Header;
