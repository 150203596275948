import { useNavigate } from 'react-router-dom';
import { Typography, Box } from '@mui/material';

const RecipeDisplay = ({ recipe }) => {
  const navigate = useNavigate();

  if (!recipe) {
    return null;
  }

  const handleOpenRecipe = () => {
    navigate(`/recipe/${recipe.id}`); // Assuming the recipe object has an `id` field.
  };


  const sanitizedUrl = (url) => url
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/,/g, '%2C');

  return (
    <Box
      onClick={handleOpenRecipe}
      sx={{
        cursor: 'pointer',
        position: 'relative',
        padding: '20px',
        borderRadius: '20px',
        textAlign: 'center',
        marginTop: '20px',
        height: '240px',
        // aspectRatio: '1.33333px',
        // paddingTop: '75%',
        paddingBottom: '75%',
        backgroundImage: `url(${sanitizedUrl(recipe.image_url || recipe.imageURL)})`,
        backgroundSize: 'cover',
        color: 'white',
      }}
    >
      {/* Dark Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
          zIndex: 1, // Ensure the overlay is above the image
        }}
      />
      <Typography 
        variant="h6" 
        sx={{ 
          fontWeight: 'bold', 
          color: 'white',
          position: 'relative',
          zIndex: 2 
        }}
      >
        {recipe.title}
      </Typography>
    </Box>
  );
};

export default RecipeDisplay;

