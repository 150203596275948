import React, { forwardRef, useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

const LoginModal = forwardRef(({ open, onClose, setUser }, ref) => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    if (authUser) {
      setUser(authUser); // Update parent state with authenticated user
      onClose(); // Close the modal
    }
  }, [authUser, onClose, setUser]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="auth-modal"
    >
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#fff',
          p: 4,
          borderRadius: 2,
          width: '100%',
          maxWidth: '800px',
          height: '100%',
          maxHeight: '95vh',
          overflow: 'auto',
        }}
      >
        <Authenticator
          socialProviders={['amazon', 'google']}
          initialState="signIn"
          loginMechanisms={['email']}
          signUpAttributes={['email']}
          hideSignUp={false}
          variation="modal"
          components={{
            Header: () => null,
            Footer: () => null,
          }}
        >
          {({ user }) => {
            if (user && user !== authUser) {
              // Avoid redundant state updates
              setAuthUser(user);
            }
            return null; // No UI needed here
          }}
        </Authenticator>
      </Box>
    </Modal>
  );
});

LoginModal.displayName = 'LoginModal';

export default LoginModal;
