import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';


const MyRecipes = () => {
  const { t } = useTranslation();
  return (
    <Button component={Link} to="/my-recipes" color="inherit">
      {t('myRecipes.title')}
    </Button>
  );
};

export default MyRecipes;
