// Amplify configuration
const amplifyConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: 'safeplates.auth.eu-north-1.amazoncognito.com',
          scopes: ['openid','email','profile','aws.cognito.signin.user.admin'],
          redirectSignIn: ["http://localhost:3000/", "https://www.safeplates.de"],
          redirectSignOut: ["http://localhost:3000/", "https://www.safeplates.de"],
          responseType: 'code',
        },
        email: true,
        phone: false,
        username: true
      },
    }
  }
};

export default amplifyConfig;