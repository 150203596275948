import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import SearchIcon from '@mui/icons-material/Search';
import KitchenIcon from '@mui/icons-material/Kitchen';

const HowItWorksSection = () => {
  const { t } = useTranslation();

  const steps = [
    {
      title: t('howItWorks.selectPreferences'),
      description: t('howItWorks.selectPreferencesDesc'),
      icon: <SearchIcon fontSize="large" color="primary" />,
    },
    {
      title: t('howItWorks.generateRecipes'),
      description: t('howItWorks.generateRecipesDesc'),
      icon: <FastfoodIcon fontSize="large" color="secondary" />,
    },
    {
      title: t('howItWorks.cookEnjoy'),
      description: t('howItWorks.cookEnjoyDesc'),
      icon: <KitchenIcon fontSize="large" color="success" />,
    },
  ];

  return (
    <Box sx={{ py: 6, backgroundColor: 'background.paper' }}>
      <Typography variant="h4" align="center" gutterBottom>
        {t('howItWorks.title')}
      </Typography>
      <Typography variant="subtitle1" align="center" color="text.secondary" gutterBottom>
        {t('howItWorks.subtitle')}
      </Typography>

      <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
        {steps.map((step, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                textAlign: 'center',
                borderRadius: 2,
                minHeight: '180px',
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {step.icon}
              <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                {step.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {step.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HowItWorksSection;
