import React, { useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  EmailShareButton,
  RedditShareButton,
} from 'react-share';
import { Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { Facebook, Twitter, WhatsApp, LinkedIn, Link, Print, Pinterest, Telegram, Email, Reddit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';

const apiUrl = process.env.REACT_APP_BACKEND_ENV === 'development' 
  ? 'http://localhost:5000'
  : process.env.REACT_APP_API_URL;

const SocialShareModal = ({ recipe, visibility, onVisibilityChange }) => {
  const { t } = useTranslation();
  const currentUrl = `${window.location.origin}/recipe/${recipe.id}`;
  const pinterestMedia = recipe.image_url || '';
  const [openDialog, setOpenDialog] = useState(false);

  const beforeOnClick = () => {
    if (!visibility) {
      setOpenDialog(true);
      return false; // Prevents sharing
    }
    return true; // Allows sharing
  };

  const handleCopyLink = () => {
    if (visibility) {
      navigator.clipboard.writeText(currentUrl);
      toast.success(t('socialShare.linkCopied'));
    } else {
      setOpenDialog(true);
    }
  };

  const handlePrint = () => {
    if (visibility) {
      window.print();
    } else {
      setOpenDialog(true);
    }
  };

  const makeRecipePublic = async () => {
    try {
      let session = await fetchAuthSession();
      const token = session.tokens.idToken.toString();
      await axios.patch(`${apiUrl}/api/recipes/${recipe.id}/privacy`, { visibility: 'public' }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(t('recipe.nowPublic'));
      onVisibilityChange(true);
    } catch (error) {
      console.error('Error updating recipe visibility:', error);
      toast.error(t('error.updatingVisibility'));
    } finally {
      setOpenDialog(false);
    }
  };

  return (
    <>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: 2, 
          mt: 2, 
          overflowX: 'auto', // Enable horizontal scrolling
          whiteSpace: 'nowrap', // Prevent items from wrapping
          paddingBottom: '10px', // Add padding for better scroll visibility
        }}
      >
        <FacebookShareButton 
          url={currentUrl} 
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton aria-label="Share on Facebook">
            <Facebook sx={{ fontSize: '2rem' }} />
          </IconButton>
        </FacebookShareButton>

        <TwitterShareButton 
          url={currentUrl} 
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton aria-label="Share on Twitter">
            <Twitter sx={{ fontSize: '2rem' }} />
          </IconButton>
        </TwitterShareButton>

        <LinkedinShareButton 
          url={currentUrl} 
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton aria-label="Share on LinkedIn">
            <LinkedIn sx={{ fontSize: '2rem' }} />
          </IconButton>
        </LinkedinShareButton>

        <WhatsappShareButton 
          url={currentUrl} 
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton aria-label="Share on WhatsApp">
            <WhatsApp sx={{ fontSize: '2rem' }} />
          </IconButton>
        </WhatsappShareButton>

        <PinterestShareButton 
          url={currentUrl} 
          media={pinterestMedia}
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton>
            <Pinterest sx={{ fontSize: '2rem' }} />
          </IconButton>
        </PinterestShareButton>

        <TelegramShareButton 
          url={currentUrl} 
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton>
            <Telegram sx={{ fontSize: '2rem' }} />
          </IconButton>
        </TelegramShareButton>

        <IconButton aria-label="Copy Link" onClick={handleCopyLink}>
          <Link sx={{ fontSize: '2rem' }} />
        </IconButton>

        <EmailShareButton 
          url={currentUrl} 
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton>
            <Email sx={{ fontSize: '2rem' }} />
          </IconButton>
        </EmailShareButton>

        <RedditShareButton 
          url={currentUrl} 
          beforeOnClick={beforeOnClick}
          openShareDialogOnClick={visibility}
        >
          <IconButton>
            <Reddit sx={{ fontSize: '2rem' }} />
          </IconButton>
        </RedditShareButton>

        <IconButton aria-label="Print to PDF" onClick={handlePrint}>
          <Print sx={{ fontSize: '2rem' }} />
        </IconButton>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{t('socialShare.makePublicTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('socialShare.makePublicMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={makeRecipePublic} color="primary">
            {t('ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SocialShareModal;
