import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const FAQSection = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      question: t('faq.isFree', 'Is the app free?'),
      answer: t('faq.isFreeAnswer', 'Yes, SafePlates is free to use with optional premium features.'),
    },
    {
      question: t('faq.offline', 'Can I use it offline?'),
      answer: t('faq.offlineAnswer', 'Currently, an internet connection is required to generate recipes and sync data.'),
    },
    {
      question: t('faq.dataHandling', 'How does SafePlates handle my data?'),
      answer: t(
        'faq.dataHandlingAnswer',
        'Your data is securely stored and only used to provide personalized services. We do not share your data with third parties.'
      ),
    },
  ];

  return (
    <Box sx={{ py: 6, px: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 4 }}>
        {t('faq.title', 'Frequently Asked Questions')}
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index} sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: 'bold' }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;
