import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import RecipeDisplay from '../components/RecipeDisplay'; // Ensure this path matches your project structure
import { useTranslation } from 'react-i18next';
import { fetchAuthSession } from '@aws-amplify/auth';

const apiUrl =
  process.env.REACT_APP_BACKEND_ENV === 'development'
    ? 'http://localhost:5000' // Local backend for testing
    : process.env.REACT_APP_API_URL; // Production API

const MyRecipesPage = () => {
  const { t } = useTranslation();
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const fetchUserRecipes = async () => {
      let session;
      try {
        session = await fetchAuthSession();
        if (!session?.tokens?.idToken) {
          setIsAuthenticated(false);
          setLoading(false);
          return;
        }

        const token = session.tokens.idToken.toString();
        const response = await axios.get(`${apiUrl}/api/recipes`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setRecipes(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching recipe:', error);
        setLoading(false);
      }
    };
    fetchUserRecipes();
  }, [t]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return (
      <Typography variant="h6" align="center" mt={4}>
        {t('myRecipes.loginPrompt')}
      </Typography>
    );
  }

  if (!recipes || recipes.length === 0) {
    return (
      <Typography variant="h6" align="center" mt={4}>
        {t('myRecipes.noRecipes')}
      </Typography>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('myRecipes.title')}
      </Typography>
      <Box sx={{ mt: 4, mb: 4, borderBottom: '1px solid #e0e0e0' }}></Box>
      <Grid container spacing={2}>
        {recipes.map((recipe) => (
          <Grid item xs={12} sm={6} md={4} key={recipe.id}>
            <RecipeDisplay recipe={recipe} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MyRecipesPage;
