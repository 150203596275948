import React from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import RecipeIcon from '@mui/icons-material/RestaurantMenu';
import IngredientsIcon from '@mui/icons-material/ShoppingBasket';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShareIcon from '@mui/icons-material/Share';
import { useTranslation } from 'react-i18next';

const features = [
  {
    icon: RecipeIcon, // Pass the actual component, not JSX
    titleKey: 'features.recipePersonalization',
    descriptionKey: 'features.recipePersonalizationDescription',
  },
  {
    icon: IngredientsIcon,
    titleKey: 'features.recipeInformation',
    descriptionKey: 'features.recipeInformationDescription',
  },
  {
    icon: DashboardIcon,
    titleKey: 'features.userDashboard',
    descriptionKey: 'features.userDashboardDescription',
  },
  {
    icon: ShareIcon,
    titleKey: 'features.socialSharing',
    descriptionKey: 'features.socialSharingDescription',
  },
];

const FeaturesSection = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        py: 8,
        px: { xs: 3, md: 8 },
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
        {t('features.title', 'Key Features')}
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => {
          const IconComponent = feature.icon; // Extract the icon component
          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  boxShadow: 3,
                  borderRadius: '16px',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    transition: '0.3s',
                  },
                  minHeight: '180px',
                  height: '100%',
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <IconComponent fontSize="large" color="primary" /> {/* Render as JSX */}
                  </Box>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    {t(feature.titleKey, 'Feature Title')}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {t(feature.descriptionKey, 'Feature Description')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default FeaturesSection;
