import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Title = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="h4" gutterBottom>
      {t('title')}
    </Typography>
  );
};

export default Title;