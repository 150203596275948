import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import RecipeDisplay from './RecipeDisplay';
import { useTranslation } from 'react-i18next';
import LoginModal from './LoginModal';
import { fetchAuthSession } from 'aws-amplify/auth';
import Title from './recipeform/Title';
import Disclaimer from './recipeform/Disclaimer';
import DishField from './recipeform/DishField';
import DietaryRestrictionsField from './recipeform/DietaryRestrictionsField';
import CommonRestrictions from './recipeform/CommonRestrictions';
import SubmitButton from './recipeform/SubmitButton';
import ErrorDisplay from './recipeform/ErrorDisplay';

// Move API configuration to a separate constant
const API_CONFIG = {
  url: process.env.REACT_APP_BACKEND_ENV === 'development' 
    ? 'http://localhost:5000' 
    : process.env.REACT_APP_API_URL,
  endpoints: {
    generateRecipe: '/api/recipes/generate'
  }
};

const STAGE_INTERVAL = 5000; // 5 seconds

const RecipeForm = ({ user, setUser }) => {
  // Group related state
  const [formData, setFormData] = useState({
    dish: '',
    dietaryRestrictions: ''
  });
  const [status, setStatus] = useState({
    loading: false,
    error: null,
    isLoginModalOpen: false
  });
  const [recipe, setRecipe] = useState(null);
  
  const { t, i18n } = useTranslation();
  const [stage, setStage] = useState(t('recipeform.generatingRecipe'));

  // Memoize stages array to prevent unnecessary re-renders
  const stages = useMemo(() => [
    t('recipeform.generatingRecipe'),
    t('recipeform.refiningRecipe'),
    t('recipeform.extractingMetadata'),
    t('recipeform.creatingMealImage'),
  ], [t]);

  // Stage rotation effect
  useEffect(() => {
    if (!status.loading) return;

    let stageIndex = 0;
    const interval = setInterval(() => {
      if (stageIndex < stages.length - 1) {
        setStage(stages[stageIndex]);
        stageIndex += 1;
      }
    }, STAGE_INTERVAL);

    return () => clearInterval(interval);
  }, [status.loading, stages]);

  // API call handlers
  const handleAuthSession = async () => {
    try {
      const session = await fetchAuthSession();
      if (!session?.tokens?.idToken) {
        throw new Error('No valid session');
      }
      return session.tokens.idToken.toString();
    } catch (error) {
      console.error('Auth error:', error);
      setStatus(prev => ({ ...prev, isLoginModalOpen: true }));
      return null;
    }
  };

  const generateRecipe = useCallback(async () => {
    setStatus(prev => ({ ...prev, loading: true, error: null }));

    try {
      const token = await handleAuthSession();
      if (!token) return;

      if (!formData.dish.trim()) {
        throw new Error(t('error.noIngredients'));
      }

      const response = await axios.post(
        `${API_CONFIG.url}${API_CONFIG.endpoints.generateRecipe}`,
        {
          dish: formData.dish,
          dietaryRestrictions: formData.dietaryRestrictions,
          language: i18n.language,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const { id, title, instructions, image_url, time, num_portions, calories } = response.data;
      setRecipe({ id, title, instructions, imageURL: image_url, time, num_portions, calories });
    } catch (error) {
      console.error('Recipe generation error:', error);
      setStatus(prev => ({ ...prev, error: t('error.generic') }));
    } finally {
      setStatus(prev => ({ ...prev, loading: false }));
    }
  }, [formData, i18n.language, t]);

  // Event handlers
  const handleSubmit = (event) => {
    event.preventDefault();
    generateRecipe();
  };

  const handleLoginModalClose = useCallback(() => {
    setStatus(prev => ({ ...prev, isLoginModalOpen: false }));
    
    if (user && formData.dish.trim()) {
      generateRecipe();
    }
  }, [user, formData.dish, generateRecipe]);

  // Form field handlers
  const handleFormChange = (field) => (value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const formStyles = {
    container: {
      backgroundColor: 'background.paper',
      padding: '40px',
      borderRadius: '8px',
      boxShadow: 2,
      marginTop: '40px',
      maxWidth: '800px',
      marginX: 'auto',
      textAlign: 'center',
    },
    recipeDisplay: {
      maxWidth: '400px',
      mx: 'auto'
    }
  };

  return (
    <>
      <LoginModal 
        open={status.isLoginModalOpen} 
        onClose={handleLoginModalClose}
        setUser={setUser}
      />
      <Box sx={formStyles.container}>
        <Title />
        
        <Box sx={{ mb: 3 }}>  
          <form onSubmit={handleSubmit}>
            <DishField 
              dish={formData.dish} 
              setDish={handleFormChange('dish')} 
            />
            <DietaryRestrictionsField 
              dietaryRestrictions={formData.dietaryRestrictions} 
              setDietaryRestrictions={handleFormChange('dietaryRestrictions')} 
            />
            <CommonRestrictions 
              dietaryRestrictions={formData.dietaryRestrictions} 
              setDietaryRestrictions={handleFormChange('dietaryRestrictions')} 
            />
            <SubmitButton loading={status.loading} stage={stage} />
          </form>
        </Box>

        <Disclaimer />

        <Box sx={formStyles.recipeDisplay}>
          <RecipeDisplay recipe={recipe} />
        </Box>

        {status.error && <ErrorDisplay error={status.error} />}
      </Box>
    </>
  );
};

export default RecipeForm;
